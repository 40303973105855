<template>
  <div class="footer-dark">
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-3 mb-md-5 item">
            <h3>Adresa</h3>
            <ul class="list-inline">
              <li>
                <p>
                  <font-awesome-icon
                    class="mr-2 text-secondary"
                    icon="fa-solid fa-location-dot"
                  />
                  Vuka Karadzica 17 <br />
                  Priboj, Srbija
                </p>
              </li>
              <li class="list-inline-item">
                <a href="mailto: abc@example.com">
                  <font-awesome-icon
                    class="fa-2x text-secondary"
                    icon="fa-solid fa-envelope"
                /></a>
              </li>
              <li class="list-inline-item">
                <a href="tel:+ 381 64/1799-351">
                  <font-awesome-icon
                    class="fa-2x text-secondary"
                    icon="fa-solid fa-square-phone"
                  />
                </a>
              </li>
              <li class="list-inline-item">
                <a href="#!">
                  <font-awesome-icon
                    class="fa-2x text-secondary"
                    icon="fa-brands fa-facebook "
                /></a>
              </li>
              <li class="list-inline-item">
                <a
                  href="https://instagram.com/stanisic38?utm_medium=copy_link"
                  target="_blank"
                  ><font-awesome-icon
                    class="fa-2x text-secondary"
                    icon="fa-brands fa-instagram-square"
                /></a>
              </li>
            </ul>
            <logo />
          </div>
          <div class="col-12 col-md-6 col-lg-3 mb-md-5 item">
            <h3>Radno vreme</h3>
            <ul>
              <li><span>Ponedeljak: </span>16h-20h</li>
              <li><span>Utorak: </span>16h-20h</li>
              <li><span>Sreda: </span>16h-20h</li>
              <li><span>Četvrtak: </span>16h-20h</li>
              <li><span>Petak: </span>16h-20h</li>
              <li><span>Subota: </span>16h-20h</li>
              <li><span>Nedelja: </span>Ne radimo</li>
            </ul>
          </div>
          <div class="col-12 col-md-6 col-lg-3 item">
            <h3>Linkovi</h3>
            <b-navbar-nav class="ml-auto">
              <b-dropdown-item to="/">Početna</b-dropdown-item>
              <b-dropdown-item to="/o-nama">O nama</b-dropdown-item>
              <b-dropdown-item to="/usluge">Usluge</b-dropdown-item>
              <b-dropdown-item to="/galerija">Galerija</b-dropdown-item>
              <b-dropdown-item to="/cenovnik">Cenovnik</b-dropdown-item>
              <b-dropdown-item to="/kontakt">Kontakt</b-dropdown-item>
            </b-navbar-nav>
          </div>
          <div class="col-12 col-md-6 col-lg-3 item text">
            <h3>Lokacija</h3>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2891.1368973950784!2d19.534889314620333!3d43.562030466340865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4758177f3ce4f427%3A0x8aa934c369ac5506!2sVuka%20Karad%C5%BEic%CC%81a%2017%2C%20Priboj%2031330!5e0!3m2!1sen!2srs!4v1644604086846!5m2!1sen!2srs"
              width="100%"
              height="200"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              class="frame"
            ></iframe>
          </div>
        </div>
        <p class="copyright"><span>MJcoding</span> © 2022</p>
      </div>
    </footer>
  </div>
</template>

<script>
import Logo from "../layout/Logo.vue";
export default {
  name: "Footer",
  components: {
    Logo,
  },
};
</script>

<style lang="scss"></style>
